import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
//import Antd from 'ant-design-vue';
//import 'ant-design-vue/dist/antd.css';
import { Button,Layout,Row,Col,Icon ,Form,Input,Tabs ,Modal,Card,notification  } from 'ant-design-vue';
import router from '../router/index'
Vue.config.productionTip = false
Vue.prototype.$axios=axios;
Vue.use(Button);
Vue.use(Layout);
Vue.use(Row);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Form);
Vue.use(Tabs);
Vue.use(Modal);
Vue.use(Card);
Vue.use(notification );
Vue.prototype.$notification = notification;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
