import Vue from 'vue'
import Router from 'vue-router'
// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
Vue.use(Router)
export default new Router({
//去掉url上的#
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [{
    path: '/',
    name: 'login',
    component: () => import('../src/Login.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../src/Index.vue'),
  }
],
})

